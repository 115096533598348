<template>
  <section id="dashboard-analytics">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>

          <!-- companies -->
          <b-col
            v-show="userData.userRoleId===userRole.ADMIN"
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="id-company"
                v-model="company"
                placeholder="Select Company"
                :clearable="false"
                label="companyname"
                class="flex-grow-1 ml-50 mr-1"
                :options="getAllCompanies"
                @input="changeCompany()"
              />
            </div>
          </b-col>
          <!-- Search Competition-->
          <b-col
            cols="12"
            :md="userData.userRoleId===userRole.ADMIN ? 4 : 6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <v-select
                id="id-competition"
                v-model="competition"
                :options="competitions"
                label="c_string_swe"
                :clearable="false"
                class="flex-grow-1 ml-50 mr-1"
                @input="changeCompetition()"
              />
            </div>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-button
              v-if="userData.userRoleId===userRole.ADMIN || userData.userRoleId===userRole.COMPANY_ADMIN"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$router.push({ name: 'game-view', params: { id: competition.encrypt_id }})"
            >
              <span>{{ $t('VIEW_GAMES') }} </span>
              <feather-icon icon="AwardIcon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row class="match-height">

      <b-col
        lg="12"
        md="12"
      >
        <analytics-game-view
          v-if="competition.encrypt_id"
          :base-competition="competition"
          :encrypt-id="competition.encrypt_id"
          :fetch-competitions="fetchCompetitions"
        />
      </b-col>
    </b-row>
    <b-row v-show="competition.record_status===0">
      <b-col
        cols="12"
        md="4"
      >
        <game-time-line
          v-if="competition.encrypt_id"
          :is-show-report="false"
          class="p-2"
          :encrypt-id="competition.encrypt_id"
          :settimes="getAllSettimes"
        />
      </b-col>
      <b-col
        cols="12"
        md="8"
      >
        <game-activity-list
          v-if="competition.encrypt_id"
          class="p-2"
          :encrypt-id="competition.encrypt_id"
          :competition-tasks="getGameTasks"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import constants from '@/constants/static.json'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import useJwt from '@/auth/jwt/useJwt'
import AnalyticsGameView from './AnalyticsGameView.vue'
import useAnalytics from './useAnalytics'
import GameActivityList from '../../games/game-view/GameActivityList.vue'
import GameTimeLine from '../../games/game-view/GameTimeLine.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    vSelect,
    AnalyticsGameView,
    GameActivityList,
    GameTimeLine,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinDate],
  data() {
    return {
      data: {},
      companies: [],
      competitions: [],
      competitionsAll: [],
      company: constants.EMPTY_COMPANY,
      currentDate: new Date(),
      tasks: [],
      setResults: [],
      performedTasks: [],
      competition: useJwt.getContent(),
      userData: useJwt.getUser(),
      userRole: constants.USER_ROLE,
    }
  },
  setup() {
    const { sliceSortByValue } = useAnalytics()
    const {
      successMessage,
      errorMessage,
    } = mixinAlert()

    return {
      sliceSortByValue,
      successMessage,
      errorMessage,
    }
  },
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters('game', ['getAllGames']),
    ...mapGetters('settime', ['getAllSettimes']),
    ...mapGetters('task', ['getGameTasks']),
  },
  async created() {
    await this.fetchAllCompanies()
    await this.fetchCompetitions()
    this.changeCompetition()
  },
  methods: {
    ...mapActions('company', ['fetchAllCompanies']),
    ...mapActions('game', ['fetchAllGames']),
    ...mapActions('task', ['fetchGameTaskByID']),
    ...mapActions('settime', ['fetchAllSettimes']),
    ...mapActions('onboarding', ['fetchGame']),
    async fetchCompetitions() {
      this.fetchAllGames().then(response => {
        if (response) {
          this.competitionsAll = response
          this.competitions = response
        }
      }).catch(() => {
        this.errorMessage('There was an issue with fetching the game list')
      })
    },
    changeCompany() {
      this.competitions = this.competitionsAll.filter(i => i.companyId === this.company.companyID)
      if (this.competitions.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        this.competition = this.competitions[0]
        this.changeCompetition()
      } else {
        this.competition = constants.EMPTY_COMPETITION
      }
    },
    changeCompetition() {
      this.fetchGameTaskByID(this.competition.ID)
      this.fetchAllSettimes(this.competition.ID)
      useJwt.setContent(this.competition)
    },
  },
}
</script>
