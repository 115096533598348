<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <!-- metting header -->
      <div class="meetup-header d-flex align-items-center">
        <div class="meetup-day">
          <feather-icon
            icon="AwardIcon"
            size="34"
            class="mr-50 text-primary"
          />
        </div>
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ competition.c_string_swe }}
            <b-badge
              :variant="`light-${resolveGameStatusVariant(competition).variant}`"
            >
              {{ resolveGameStatusVariant(competition).text }}
            </b-badge>
            <b-badge
              class="ml-1"
              variant="light-info"
            >
              {{ resolveGameType(competition.gametype) }}
            </b-badge>
            <b-button
              v-if="competition.record_status===0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="shadow ml-1"
              @click="onPublish(competition)"
            >
              <span>{{ $t('PUBLISH_GAME') }} </span>
            </b-button>
          </b-card-title>
          <b-card-text class="mb-0">
            <p v-html="competition.visionstatement" />
          </b-card-text>
        </div>
      </div>
      <!--/ metting header -->
      <!-- start date -->
      <b-media
        no-body
      >
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(competition.startdate) }}
          </h6>
        </b-media-body>
      </b-media>
      <b-media
        no-body
      >
        <!-- end date -->
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              icon="CalendarIcon"
              size="18"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h6 class="mt-0 pt-50">
            {{ formatDateTime(competition.enddate) }}
          </h6>
        </b-media-body>
      </b-media>

      <!--Landing url: -->
      <b-media
        v-if="competition.record_status!==0"
        no-body
      >

        <b-media-aside

          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(landingLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="landingLink"
          > {{ $t('LANDING_LINK') }} </b-link>
        </b-media-body>
      </b-media>

      <!--Players invitation link: -->
      <b-media
        v-if="competition.record_status!==0"
        no-body
      >

        <b-media-aside

          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(invitationLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="invitationLink"
          > {{ $t('PLAYERS_INVITATION_LINK') }} </b-link>
        </b-media-body>
      </b-media>

      <!--Leaderboard link -->
      <b-media
        v-if="competition.record_status!==0"
        no-body
      >

        <b-media-aside

          class="mr-1"
        >
          <b-avatar
            rounded
            variant="light-primary"
            size="34"
          >
            <feather-icon
              class="cursor-pointer"
              icon="Link2Icon"
              size="18"
              @click="copyLink(leaderboardLink)"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <b-link
            class="mt-0 pt-50"
            target="_blank"
            :href="leaderboardLink"
          > {{ $t('LEADER_BOARD') }}</b-link>
        </b-media-body>
      </b-media>

      <!-- avatar group -->
      <b-avatar-group
        class="mt-2 pt-50"
        size="33"
      >
        <b-avatar
          v-for="(user,index) in competition.users.slice(0, 12)"
          :key="user.userID"
          v-b-tooltip.hover.bottom="user.firstname + ' ' + user.lastname"
          :variant="changeVariant(index)"
          :text="avatarText(user.firstname.substring(0,1))"
          class="pull-up"
          :to="{ name: 'user-profile-id', params: { id: user.userID } }"
        />
        <span
          v-if="competition.users.length > 12"
          class="ml-1"
        > ...</span>
        <h6 class="align-self-center cursor-pointer ml-1 mb-0">
          {{ competition.usersCount }} Players  -  {{ competition.groupCount }} Teams
        </h6>
      </b-avatar-group>

    </b-card-body>
  </b-card>

</template>

<script>
import {
  BCard, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BBadge,
  BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BButton, BLink,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/named
import { useClipboard } from '@vueuse/core'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import { mapActions } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { mixinDate } from '@/constants/mixinDate'
import mixinAlert from '@/constants/mixinAlert'
import { countingObject } from '@/constants/utils'
import useGameList from '../../games/game-list/useGameList'
import { mixinList } from '@/constants/mixinValidations'
import useJwt from '@/auth/jwt/useJwt'

/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BButton,
    BLink,
    BBadge,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [mixinDate, mixinList],
  props: {
    encryptId: {
      type: String,
      required: true,
    },
    baseCompetition: {
      type: Object,
      required: true,
    },
    fetchCompetitions: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      competition: {
        ID: 0,
        encrypt_id: '',
        c_string_swe: '',
        startdate: '',
        enddate: '',
        starttime: '',
        endtime: '',
        visionstatement: '',
        record_status: 0,
        usersCount: '0',
        gametype: 0,
        groupCount: 0,
        users: [],
        groups: [],
      },
      invitationLink: '',
      leaderboardLink: '',
      landingLink: '',
    }
  },
  watch: {
    baseCompetition: {
      handler() {
        if (this.baseCompetition) this.fetchGame(this.baseCompetition.encrypt_id)
      },
    },
  },
  created() {
    if (this.baseCompetition) this.fetchGame(this.baseCompetition.encrypt_id)
  },
  methods: {
    ...mapActions('onboarding', ['fetchAllGame']),
    ...mapActions('game', ['updateGame']),
    fetchGame(id) {
      this.fetchAllGame(id).then(response => {
        if (response.data) {
          const { data } = response
          const resuts = countingObject(data.groups)
          this.competition = data
          useJwt.setContent(this.competition)
          this.setLink(this.competition)
          this.competition.groups = data.groups
          this.competition.usersCount = resuts.usersCount
          this.competition.groupCount = resuts.groupCount
          this.competition.users = resuts.users
        }
      })
    },
    setLink(data) {
      this.invitationLink = `${process.env.VUE_APP_HOST}onboarding-user/${data.encrypt_id}`
      this.leaderboardLink = `${process.env.VUE_APP_HOST}leaderboard/${data.encrypt_id}`
      this.landingLink = `${process.env.VUE_APP_HOST}${data.encrypt_id}`
    },
    onPublish(data) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.PUBLISH_CONFIRMATION')} ${data.c_string_swe}  game ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.baseCompetition.record_status = 1
            // eslint-disable-next-line no-param-reassign
            data.record_status = 1
            this.updateGame(data).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.GAME_PUBLISHED'))
              }
            })
          }
        })
    },
  },
  setup() {
    const toast = useToast()
    const { copy } = useClipboard()

    const copyLink = link => {
      copy(link)

      toast({
        component: ToastificationContent,
        props: {
          title: 'The link is copied',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })
    }

    const {
      resolveGameStatusVariant,
    } = useGameList()

    const {
      errorMessage, successMessage,
    } = mixinAlert()

    return {
      copyLink,
      successMessage,
      errorMessage,
      avatarText,
      resolveGameStatusVariant,
    }
  },
}
</script>
